export const ROUTES = {
    HOME: '',
    ACCOUNTS: 'accounts',
    ACCOUNT: 'account',
    ACCOUNT_NEW: 'account-new',
    ACCOUNT_TO_PROPERTY: 'account-to-property',
    ACCOUNT_TO_PROPERTY_NEW: 'account-to-property-new',
    CONTRACTS: 'contracts',
    CONTRACT: 'contract',
    CONTRACT_NEW: 'contract-new',
    CLAIM_REACT: 'claims',
    PRODUCT_TO_CONTRACT: 'product-to-contract',
    PRODUCT_TO_CONTRACT_NEW: 'product-to-contract-new',
    PROPERTIES: 'properties',
    PROPERTY: 'property',
    PROPERTY_NEW: 'property-new',
    CUSTOMERS: 'customers',
    BASE: '',
    SEARCH: 'search',
    FORGOT_PASSWORD: 'forgot-password',
    LOGIN: 'login',
    NOT_FOUND: '404',
    PROFILE: 'profile',
    TERRITORIES: 'territories',
    PRODUCTS: 'products',
    PRODUCT: 'product',
    PRODUCT_NEW: 'product-new',
    PRODUCT_TO_PACKAGE: 'product-to-package',
    PRODUCT_TO_PACKAGE_NEW: 'product-to-package-new',
    PRODUCT_PACKAGE: 'package',
    PRODUCT_PACKAGES: 'packages',
    PRODUCT_PACKAGE_NEW: 'package-new',
    PRICEBOOK: 'pricebook',
    PRICEBOOKS: 'pricebooks',
    PRICEBOOK_NEW: 'pricebook-new',
    PRICEBOOK_ENTRY: 'pricebook-entry',
    PRICEBOOK_ENTRIES: 'pricebook-entries',
    PRICEBOOK_ENTRY_NEW: 'pricebook-entry-new',
    NOTES: 'notes',
    NOTE: 'note',
    NOTE_NEW: 'note-new',
    PROMO_CODES: 'promo-codes',
    PROMO_CODE: 'promo-code',
    PROMO_CODE_NEW: 'promo-code-new',
    PROMO_CODE_TYPES: 'promo-code-types',
    PROMO_CODE_TYPE: 'promo-code-type',
    PROMO_CODE_TYPE_NEW: 'promo-code-type-new',
    PROMO_CODE_FAMILIES: 'promo-code-families',
    PROMO_CODE_FAMILY: 'promo-code-family',
    PROMO_CODE_FAMILY_NEW: 'promo-code-family-new',
    PRODUCT_TO_PROMOCODES: 'promo-code-products',
    PRODUCT_TO_PROMOCODE: 'promo-code-product',
    PRODUCT_TO_PROMOCODE_NEW: 'promo-code-product-new',
    PACKAGE_TO_PROMOCODES: 'promo-code-packages',
    PACKAGE_TO_PROMOCODE: 'promo-code-package',
    PACKAGE_TO_PROMOCODE_NEW: 'promo-code-package-new',
    REFERRALS: 'referrals',
    REFERRAL: 'referral',
    REFERRAL_NEW: 'referral-new',
    RENEWALS: 'renewals',
    RENEWAL: 'renewal',
    RENEWAL_NEW: 'renewal-new',
    PRODUCT_TO_RENEWAL: 'product-to-renewal',
    CONTROLLER: 'controller',
    SALES_PORTAL: 'sales-portal',
    ANALYTICS: 'analytics',
}

import { LitElement, html, css } from 'lit-element';

// These are the shared styles needed by this element.
import { htPandoraEditableStyles } from './styles/ht-pandora-editable-styles';
import { config } from '../functions/config';
import { postDataFactory } from '../functions/postDataFactory';

import './htPandoraFormWrapper';

class htPandoraEditableRenewalDiscount extends LitElement {
    static get properties() {
        return {
            renewal: { type: Object },
            fields: { type: Array },
            _complete: { type: Boolean },
            discountedPrice: { type: Number },
        };
    }

    constructor() {
        super();
        this.postData = postDataFactory({
            fetch: window.fetch,
            apiEndPoint: `${config.renewalAPI}`,
        });
        this._complete = false;
        this.fields = [];
        this.discountedPrice = 0;
    }

    _calculateRenewal() {
        const formData = JSON.parse(
            this.shadowRoot.querySelector('ht-pandora-form-wrapper').getAttribute('formdata')
        );
        this.discountedPrice = (this.renewal.last_quoted_price / 100) * formData.percentage_discount;
    }

    _submitRenewalEdit() {
        let discountedPriceToApply = 0;
        if (this.discountedPrice > 0) {
            discountedPriceToApply = this.discountedPrice;
        } else {
            const discount = JSON.parse(
                this.shadowRoot.querySelector('ht-pandora-form-wrapper').getAttribute('formdata')
            ).percentage_discount;
            discountedPriceToApply = (this.renewal.last_quoted_price / 100) * discount;
        }
        const changes = {
            id: this.renewal.id,
            custom_renewal_discount: discountedPriceToApply,
            last_quoted_price: this.renewal.last_quoted_price - discountedPriceToApply,
        };
        const sendUpdate = new CustomEvent('send-update', {
            detail: {
                changes,
                table: 'renewal',
                post: this.postData,
                element: this,
            },
        });

        this.dispatchEvent(sendUpdate);
    }

    _formCompleteToggle(e) {
        this._complete = e.detail.complete;
    }

    shouldUpdate() {
        if (this.renewal !== null) return true;
        return false;
    }

    _cancelEdits() {
        this.shadowRoot.querySelector('ht-pandora-form-wrapper').setAttribute('formdata', '{}');
    }

    _closeApplyDiscount() {
        const closeEdit = new CustomEvent('close-edit', {
            detail: {
                element: this,
            },
        });
        this.dispatchEvent(closeEdit);
    }

    static get styles() {
        return css`
            ${htPandoraEditableStyles}
        `;
    }

    render() {
        return html`
            <div class="form-section-title">
                <h2>Discounting renewal: ${this.renewal.id}</h2>
                 <button
                        class="ht-button ht-button-white ht-button-white-no-hover"
                        type="button"
                        @click="${() => this._closeApplyDiscount()}"
                    >
                        X
                    </button>
            </div>
            <form @submit="${e => e.preventDefault()}">
                <ht-pandora-form-wrapper
                    @form-complete="${this._formCompleteToggle}"
                    @form-data="${this._formDataUpdated}"
                    fields="${JSON.stringify(this.fields)}"
                    item="${JSON.stringify(this.renewal)}"
                ></ht-pandora-form-wrapper>
                 ${this.discountedPrice > 0 ? html`<p class="info">Current price:
                    £${this.renewal.last_quoted_price / 100}
                </p>
                <p class="info">New price:
                    £${((this.renewal.last_quoted_price - this.discountedPrice) / 100).toFixed(2)}
                </p>` : ''}
                <div class="buttons">
                    <button
                            class="ht-button ht-button-secondary"
                        type="button"
                        @click="${() => this._calculateRenewal()}"
                    >
                        Calculate
                    </button>
                    <button
                            ?disabled="${!this._complete}"
                            class="ht-button"
                            type="button"
                            @click="${() => this._submitRenewalEdit()}"
                        >
                            Submit
                    </button>
                </div>
            </form>
        `;
    }
}

window.customElements.define(
    'ht-pandora-editable-renewal-discount',
    htPandoraEditableRenewalDiscount
);

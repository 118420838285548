import { LitElement, html, css } from 'lit-element'

// These are the shared styles needed by this element.
import { connect } from 'pwa-helpers'
import { htPandoraEditableItemStyles } from './styles/ht-pandora-editable-item-styles'

import { changePage } from '../functions/changePage'
import { createNotificationObj } from '../functions/notificationFactory'
import { ROUTES } from '../constants/routes'
import { ACTION_FIELDS } from '../constants/fields'

import { store } from '../store'
import { removeEditableItem, addNotification } from '../actions/app'

import './htPandoraEditableContractCancel'
import './htPandoraEditableContractDates'
import './htPandoraEditableRenewalChange'
import './htPandoraEditableRenewalCancel'
import './htPandoraEditableRenewalGC'
import './htPandoraEditableRenewalReinstate'
import './htPandoraLoading'
import './htPandoraEditableRenewalDiscount'

class htPandoraEditableItem extends connect(store)(LitElement) {
    static get properties() {
        return {
            _editableItem: { type: Object },
            _editableTable: { type: String },
            _editableAction: { type: String },
            _loading: { type: Boolean },
        }
    }

    constructor() {
        super()
        this._loading = false
        this._editableItem = {}
    }

    _stateChanged(state) {
        if (state.app.editableItem) {
            this._editableItem = state.app.editableItem.item
            this._editableTable = state.app.editableItem.table
            this._editableAction = state.app.editableItem.action
        } else {
            this._editableItem = null
            this._editableAction = null
            this._editableTable = null
        }
        this._page = state.app.page
    }

    _sendUpdateRequest(changes, table, post, element, route) {
        this._loading = true

        const saveItemData = post('/update', changes)

        saveItemData
            .then((response) => response.json())
            .then((data) => {
                const error = data.data?.errorMessage || data.errorMessage;
                if (error) {
                    store.dispatch(
                        addNotification(
                            createNotificationObj(`${error}`, 'error', true, false)
                        )
                    )
                    this._loading = false
                } else {
                    this._closeEdit(element)
                    this._loading = false
                    if (this._page !== route) {
                        changePage(`${route}/${changes.id}`)
                    } else {
                        this.parentNode
                            .querySelector(`ht-pandora-item[type="${table}"]`)
                            ._getLatestItemData(changes.id)
                    }
                }
            })
            .catch((error) => {
                store.dispatch(addNotification(createNotificationObj(error, 'error', true, false)))
                this._loading = false
            })
    }

    _sendCancelRequest(changes, table, post, element, route) {
        this._loading = true

        const cancelItem = post('/cancel', {
            ...changes,
            cancel_gocardless: changes.cancel_gocardless === 'true',
            cancel_chargebee: changes.cancel_chargebee === 'true',
        })

        cancelItem
            .then((response) => response.json())
            .then((data) => {
                if (data.data.errorMessage) {
                    store.dispatch(
                        addNotification(
                            createNotificationObj(`${data.data.errorMessage}`, 'error', true, false)
                        )
                    )
                    this._loading = false
                } else {
                    this._closeEdit(element)
                    this._loading = false
                    if (this._page !== route) {
                        changePage(`${route}/${changes.id}`)
                    } else {
                        this.parentNode
                            .querySelector(`ht-pandora-item[type="${table}"]`)
                            ._getLatestItemData(changes.id)
                    }
                }
            })
            .catch((error) => {
                store.dispatch(addNotification(createNotificationObj(error, 'error', true, false)))
                this._loading = false
            })
    }

    _sendReinstateRequest(changes, table, post, element, route) {
        this._loading = true

        const saveItemData = post('/reinstate', changes)

        saveItemData
            .then((response) => response.json())
            .then((data) => {
                if (data.data.errorMessage) {
                    store.dispatch(
                        addNotification(
                            createNotificationObj(`${data.data.errorMessage}`, 'error', true, false)
                        )
                    )
                    this._loading = false
                } else {
                    this._closeEdit(element)
                    this._loading = false
                    if (this._page !== route) {
                        changePage(`${route}/${changes.id}`)
                    } else {
                        this.parentNode
                            .querySelector(`ht-pandora-item[type="${table}"]`)
                            ._getLatestItemData(changes.id)
                    }
                }
            })
            .catch((error) => {
                store.dispatch(addNotification(createNotificationObj(error, 'error', true, false)))
                this._loading = false
            })
    }

    _closeEdit(element) {
        element._cancelEdits()
        store.dispatch(removeEditableItem())
    }

    _changeLoading(loading) {
        this._loading = loading
    }

    _renderAction(table, action) {
        if (table === 'contract') {
            switch (action) {
                case 'cancellation':
                    return html`
                        <ht-pandora-editable-contract-cancel
                            contract="${JSON.stringify(this._editableItem)}"
                            fields="${JSON.stringify(ACTION_FIELDS.CONTRACT.cancellation)}"
                            @send-cancel="${(e) =>
                                this._sendCancelRequest(
                                    e.detail.changes,
                                    e.detail.table,
                                    e.detail.post,
                                    e.detail.element,
                                    ROUTES.CONTRACT
                                )}"
                            @close-edit="${(e) => this._closeEdit(e.detail.element)}"
                        ></ht-pandora-editable-contract-cancel>
                    `
                case 'date_change':
                    return html`
                        <ht-pandora-editable-contract-dates
                            contract="${JSON.stringify(this._editableItem)}"
                            fields="${JSON.stringify(ACTION_FIELDS.CONTRACT.date_change)}"
                            @send-update="${(e) =>
                                this._sendUpdateRequest(
                                    e.detail.changes,
                                    e.detail.table,
                                    e.detail.post,
                                    e.detail.element,
                                    ROUTES.CONTRACT
                                )}"
                            @close-edit="${(e) => this._closeEdit(e.detail.element)}"
                        ></ht-pandora-editable-contract-dates>
                    `
                default:
                    break
            }
        } else if (table === 'renewal') {
            switch (action) {
                case 'general_change':
                    return html`
                        <ht-pandora-editable-renewal-change
                            renewal="${JSON.stringify(this._editableItem)}"
                            fields="${JSON.stringify(ACTION_FIELDS.RENEWAL.general_change)}"
                            @send-update="${(e) =>
                                this._sendUpdateRequest(
                                    e.detail.changes,
                                    e.detail.table,
                                    e.detail.post,
                                    e.detail.element,
                                    ROUTES.RENEWAL
                                )}"
                            @close-edit="${(e) => this._closeEdit(e.detail.element)}"
                            @loading="${(e) => this._changeLoading(e.detail.loading)}"
                        ></ht-pandora-editable-renewal-change>
                    `
                case 'update_gc':
                    return html`
                        <ht-pandora-editable-renewal-gc
                            renewal="${JSON.stringify(this._editableItem)}"
                            fields="${JSON.stringify(ACTION_FIELDS.RENEWAL.update_gc)}"
                            @send-update="${(e) =>
                                this._sendUpdateRequest(
                                    e.detail.changes,
                                    e.detail.table,
                                    e.detail.post,
                                    e.detail.element,
                                    ROUTES.RENEWAL
                                )}"
                            @close-edit="${(e) => this._closeEdit(e.detail.element)}"
                            @loading="${(e) => this._changeLoading(e.detail.loading)}"
                        ></ht-pandora-editable-renewal-gc>
                    `
                case 'cancel':
                    return html`
                        <ht-pandora-editable-renewal-cancel
                            fields="${JSON.stringify(ACTION_FIELDS.RENEWAL.cancel)}"
                            renewal="${JSON.stringify(this._editableItem)}"
                            @send-update="${(e) =>
                                this._sendUpdateRequest(
                                    e.detail.changes,
                                    e.detail.table,
                                    e.detail.post,
                                    e.detail.element,
                                    ROUTES.RENEWAL
                                )}"
                            @close-edit="${(e) => this._closeEdit(e.detail.element)}"
                        ></ht-pandora-editable-renewal-cancel>
                    `
                case 'reinstate':
                    return html`
                        <ht-pandora-editable-renewal-reinstate
                            renewal="${JSON.stringify(this._editableItem)}"
                            @send-update="${(e) =>
                                this._sendReinstateRequest(
                                    e.detail.changes,
                                    e.detail.table,
                                    e.detail.post,
                                    e.detail.element,
                                    ROUTES.RENEWAL
                                )}"
                            @close-edit="${(e) => this._closeEdit(e.detail.element)}"
                        ></ht-pandora-editable-renewal-reinstate>
                    `;
                case 'apply_discount':
                    return html`
                        <ht-pandora-editable-renewal-discount
                            fields="${JSON.stringify(ACTION_FIELDS.RENEWAL.apply_discount)}"
                            renewal="${JSON.stringify(this._editableItem)}"
                            @send-update="${e => this._sendUpdateRequest(
                                e.detail.changes,
                                e.detail.table,
                                e.detail.post,
                                e.detail.element,
                                ROUTES.RENEWAL
                            )}"
                            @close-edit="${(e) => this._closeEdit(e.detail.element)}"
                        ></ht-pandora-editable-renewal-discount>
                    `;
                default:
                    break
            }
        }

        return ''
    }

    static get styles() {
        return css`
            ${htPandoraEditableItemStyles}
        `
    }

    render() {
        return html`
            <div class="floating-wrapper">
                ${this._renderAction(this._editableTable, this._editableAction)}
                <ht-pandora-loading ?active="${this._loading}"></ht-pandora-loading>
            </div>
        `
    }
}

window.customElements.define('ht-pandora-editable-item', htPandoraEditableItem)

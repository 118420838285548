import { config } from '../../functions/config'
import { ROUTES } from '../routes'
import { getFieldsItem, getFields, getActionFields } from '../../functions/fieldProcessing'
import { OPTIONS } from '../options'
import { store } from '../../store'
import { addEditableItem } from '../../actions/app'

const ACTION_GENERAL_CHANGE = 'general_change'
const ACTION_UPDATE_GC = 'update_gc'
const ACTION_CANCEL = 'cancel'
const ACTION_REINSTATE = 'reinstate'
const ACTION_DISCOUNT = 'apply_discount'

export const FIELDS = {
    status: {
        // show_field: 'status',
        // show_field_value: OPTIONS.CONTRACT_STATUS.CANCELLED,
        key: 'status',
        label: 'Status',
        type: 'renewal_status',
        required: true,
        disabled: true,
        width: '3',
        min: 0,
    },
    claims_accounted_for: {
        key: 'claims_accounted_for',
        label: 'Proposed Claim Count',
        type: 'number',
        required: true,
        disabled: true,
        width: '3',
        min: 0,
    },
    last_quoted_price: {
        key: 'last_quoted_price',
        label: 'Renewal Price',
        type: 'price',
        required: true,
        disabled: true,
        width: '3',
    },
    custom_renewal_discount: {
        key: 'custom_renewal_discount',
        label: 'Discount Applied (monthly)',
        type: 'price',
        required: false,
        disabled: true,
        width: '3',
        min: 0,
    },
    start_date: {
        key: 'start_date',
        label: 'Renewal Start Date',
        type: 'date',
        required: true,
        disabled: true,
        width: '3',
    },
    end_date: {
        key: 'end_date',
        label: 'Renewal End Date',
        type: 'date',
        required: true,
        disabled: true,
        width: '3',
    },
    saving: {
        key: 'saving',
        label: 'Saving',
        type: 'price',
        required: true,
        disabled: true,
        width: '3',
    },
    package_id: {
        key: 'package_id',
        label: 'Package',
        type: 'package',
        required: false,
        disabled: true,
        width: '12',
        linkedItem: 'package',
        linkedItemFieldsReference: 'PRODUCT_PACKAGE',
        linkUrl: ROUTES.PRODUCT_PACKAGE,
    },
    billing_type: {
        key: 'billing_type',
        label: 'Billing Type',
        type: 'contract_billing',
        required: false,
        disabled: true,
        width: '3',
    },
    contribution: {
        key: 'contribution',
        label: 'Contribution',
        type: 'radio',
        required: false,
        disabled: true,
        width: '3',
        options: OPTIONS.CONTRIBUTION,
    },
    price_report: {
        key: 'price_report',
        label: 'Price Report',
        type: 'textarea',
        required: false,
        disabled: true,
        width: '12',
    },
    go_cardless_mandate_id: {
        key: 'go_cardless_mandate_id',
        label: 'GC Mandate',
        type: 'gc_mandate',
        required: false,
        disabled: false,
        width: '4',
        linkUrl: config.gcMandateURL,
        linkUrlExternal: true,
    },
    go_cardless_customer_id: {
        key: 'go_cardless_customer_id',
        label: 'GC Customer',
        type: 'gc_customer',
        required: false,
        disabled: false,
        width: '4',
        linkUrl: config.gcCustomerURL,
        linkUrlExternal: true,
        linkedItem: 'go_cardless_customer',
    },
    contract_id: {
        key: 'contract_id',
        label: 'Contract',
        type: 'contract_id',
        required: true,
        disabled: true,
        width: '12',
        linkedItem: 'contract',
        linkedItemFieldsReference: 'CONTRACT',
        linkUrl: ROUTES.CONTRACT,
    },
    renewed_contract_id: {
        key: 'renewed_contract_id',
        label: 'Renewed Contract',
        type: 'contract_id',
        required: true,
        disabled: true,
        width: '12',
        linkedItem: 'renewed_contract',
        linkedItemFieldsReference: 'CONTRACT',
        linkUrl: ROUTES.CONTRACT,
    },
    cancellation_status: {
        show_field: 'status',
        show_field_value: OPTIONS.CONTRACT_STATUS.CANCELLED,
        key: 'cancellation_status',
        label: 'Cancellation Status',
        type: 'cancellation_status',
        required: true,
        disabled: false,
        width: '4',
        actions: [ACTION_CANCEL],
    },
    cancellation_type: {
        show_field: 'status',
        show_field_value: OPTIONS.CONTRACT_STATUS.CANCELLED,
        key: 'cancellation_type',
        label: 'Cancellation Reason',
        type: 'cancellation_type',
        required: true,
        disabled: false,
        width: '4',
        actions: [ACTION_CANCEL],
    },
    cancellation_description: {
        show_field: 'status',
        show_field_value: OPTIONS.CONTRACT_STATUS.CANCELLED,
        key: 'cancellation_description',
        label: 'Cancellation Description',
        type: 'textarea',
        required: true,
        disabled: false,
        width: '12',
        actions: [ACTION_CANCEL],
    },
    confirmation: {
        show_field: 'status',
        show_field_value: OPTIONS.CONTRACT_STATUS.CANCELLED,
        key: 'cancellation_description',
        label: 'Cancellation Description',
        type: 'textarea',
        required: true,
        disabled: false,
        width: '12',
        actions: [ACTION_REINSTATE],
    },
    percentage_discount: {
        show_field: 'status',
        show_field_value: OPTIONS.CONTRACT_STATUS.PENDING,
        key: 'percentage_discount',
        label: 'Percentage Discount',
        type: 'number',
        required: true,
        disabled: false,
        width: '4',
        actions: [ACTION_DISCOUNT],
        min: 0,
        max: 10,
    },
};

export const ITEM = getFieldsItem([
    {
        section_name: 'General',
        hideSectionName: true,
        fields: [
            FIELDS.status,
            FIELDS.claims_accounted_for,
            FIELDS.last_quoted_price,
            FIELDS.custom_renewal_discount,
            FIELDS.start_date,
            FIELDS.end_date,
            FIELDS.saving,
            FIELDS.package_id,
            FIELDS.billing_type,
            FIELDS.contribution,
            FIELDS.price_report,
            FIELDS.go_cardless_mandate_id,
            FIELDS.go_cardless_customer_id,
            FIELDS.contract_id,
            FIELDS.renewed_contract_id,
        ],
    },
])

export const QUICK_FIELDS = getFields([
    FIELDS.status,
    FIELDS.claims_accounted_for,
    FIELDS.last_quoted_price,
    FIELDS.custom_renewal_discount,
    FIELDS.start_date,
    FIELDS.end_date,
    FIELDS.saving,
    FIELDS.package_id,
    FIELDS.billing_type,
    FIELDS.contribution,
    FIELDS.price_report,
    FIELDS.go_cardless_mandate_id,
    FIELDS.go_cardless_customer_id,
    FIELDS.contract_id,
    FIELDS.renewed_contract_id,
])

export const TITLE_FIELDS = [FIELDS.start_date]

const ACTIONS = [
    {
        action_id: ACTION_CANCEL,
        name: 'Cancel Renewal',
        actionType: 'danger',
        showAction: {
            key: 'status',
            values: [
                OPTIONS.RENEWAL_STATUS.PENDING_PAYMENT_MISSING,
                OPTIONS.RENEWAL_STATUS.PENDING_MANDATE_FAILED,
                OPTIONS.RENEWAL_STATUS.PENDING_MANDATE_REQUIRED,
                OPTIONS.RENEWAL_STATUS.DRAFT,
                OPTIONS.RENEWAL_STATUS.PENDING,
            ],
        },
        callFunction: (context) => {
            store.dispatch(addEditableItem(context.item, 'renewal', ACTION_CANCEL))
        },
    },
    {
        action_id: ACTION_UPDATE_GC,
        name: 'Update GC details',
        showAction: {
            key: 'status',
            values: [
                OPTIONS.RENEWAL_STATUS.PENDING_PAYMENT_MISSING,
                OPTIONS.RENEWAL_STATUS.PENDING_MANDATE_FAILED,
                OPTIONS.RENEWAL_STATUS.PENDING_MANDATE_REQUIRED,
                OPTIONS.RENEWAL_STATUS.DRAFT,
                OPTIONS.RENEWAL_STATUS.PENDING,
            ],
        },
        actionType: 'warning',
        callFunction: (context) => {
            store.dispatch(addEditableItem(context.item, 'renewal', ACTION_UPDATE_GC))
        },
    },
    {
        action_id: ACTION_GENERAL_CHANGE,
        name: 'Change Renewal',
        actionType: 'info',
        showAction: {
            key: 'status',
            values: [
                OPTIONS.RENEWAL_STATUS.PENDING_PAYMENT_MISSING,
                OPTIONS.RENEWAL_STATUS.PENDING_MANDATE_FAILED,
                OPTIONS.RENEWAL_STATUS.PENDING_MANDATE_REQUIRED,
                OPTIONS.RENEWAL_STATUS.DRAFT,
                OPTIONS.RENEWAL_STATUS.PENDING,
            ],
        },
        callFunction: (context) => {
            store.dispatch(addEditableItem(context.item, 'renewal', ACTION_GENERAL_CHANGE))
        },
    },
    {
        action_id: ACTION_REINSTATE,
        name: 'Reinstate',
        actionType: 'danger',
        showAction: {
            key: 'status',
            values: [OPTIONS.CONTRACT_STATUS.LIVE, OPTIONS.RENEWAL_STATUS.CANCELLED],
        },
        callFunction: (context) => {
            store.dispatch(addEditableItem(context.item, 'renewal', ACTION_REINSTATE))
        },
    },
    {
        action_id: ACTION_DISCOUNT,
        name: 'Apply Discount',
        actionType: 'info',
        showAction: {
            key: 'status',
            values: [
                OPTIONS.RENEWAL_STATUS.DRAFT,
                OPTIONS.RENEWAL_STATUS.PENDING,
                OPTIONS.RENEWAL_STATUS.PENDING_MANDATE_REQUIRED,
                OPTIONS.RENEWAL_STATUS.PENDING_PAYMENT_MISSING,
                OPTIONS.RENEWAL_STATUS.PENDING_MANDATE_FAILED
            ],
        },
        callFunction: (context) => {
            store.dispatch(addEditableItem(context.item, 'renewal', ACTION_DISCOUNT));
        },
    },
];

export const ACTION_FIELDS = {};
ACTION_FIELDS[ACTION_GENERAL_CHANGE] = getActionFields([
    FIELDS.claims_accounted_for,
    FIELDS.custom_renewal_discount,
    FIELDS.billing_type,
    FIELDS.contribution,
    FIELDS.package_id,
])

ACTION_FIELDS[ACTION_UPDATE_GC] = getActionFields([
    FIELDS.go_cardless_mandate_id,
    FIELDS.go_cardless_customer_id,
])

ACTION_FIELDS[ACTION_CANCEL] = getActionFields([
    FIELDS.cancellation_status,
    FIELDS.cancellation_type,
    FIELDS.cancellation_description,
])

ACTION_FIELDS[ACTION_REINSTATE] = getActionFields([FIELDS.contract_id])
ACTION_FIELDS[ACTION_DISCOUNT] = getActionFields([FIELDS.percentage_discount]);

export const PAGE = {
    'list-url': ROUTES.RENEWALS,
    'item-url': ROUTES.RENEWAL,
    'new-url': ROUTES.RENEWAL_NEW,
    endpoint: config.renewalAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    type: 'renewal',
    actions: ACTIONS,
    'list-page': {
        title: 'Renewals',
        showAcitveFlag: true,
    },
    'item-page': {
        'readonly-expansions': 'product_to_renewal',
        expansions: 'contract,renewed_contract',
        itemEditingAllowed: false,
    },
    'new-page': {
        title: 'New Renewal',
        action: '/create',
    },
}
